import React from 'react';
import styled from 'styled-components';
import loadable from "@loadable/component"
import { graphql } from "gatsby";
import '../styles/globals.css';
// import { Navbar, Footer } from '../components';
import {
  Title,
  Text,
  Section,
  Divider,
  SectionWrapper,
  HeaderBG,
} from '../components/core';

const Footer = loadable(() => import("../components"), {
  resolveComponent: (components) => components.Footer,
});
const Navbar = loadable(() => import("../components"), {
  resolveComponent: (components) => components.Navbar,
});

const AboutUs = () => {
  return (
    <>
      <Navbar />
      <div
        style={{
          marginTop: '80px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Section background="#ffffff">
          <HeaderBG />
          <SectionWrapper>
            <Left>
              <Title fontSize="48px">Privacy Policy</Title>
              <SpaceContainer mt="100" mb="40">
                <Title fontSize="36px" mfontSize="28px">
                  Verak Privacy Policy
                </Title>
              </SpaceContainer>
              <Text mfontSize="16px">
                We collect various data points about you. <br />
                <br /> Data by which you are identified is termed as “Personal
                Data”. Personal Data does not include any information that is
                freely available or accessible in public domain.
                <br />
                <br />
                As a paying customer - you provide us ("Verak") with permission to share only your business entity name with prospective customers for reference checks. Your sensitive details such mobile number & insured items shall never be shared unless explicit permission is taken from you.
                <br/>
                <br /> Your privacy is of utmost importance to us and protection
                of your Personal Data is a key commitment for us.
              </Text>
            </Left>
          </SectionWrapper>
          <Section background="#FFF7F1">
            <SectionWrapper px="25px">
              <Left>
                <Title fontSize="36px" mfontSize="28px">
                  1. Personal Data
                </Title>
                <SpaceContainer mt="40">
                  Personal Data we may collect from you are as under:
                  <br />
                  <br />
                  <ul>
                    <li>
                      Images of documents / photos, provided by you, to access
                      your insurance by us and/or avail our services from us.
                    </li>
                    <li>
                      E-mail access, if provided by you, to search for your
                      insurance policies as required to avail our services.
                    </li>
                    <li>
                      Digilocker access, if provided by you, to search for your
                      insurance policies as required to avail our services.
                    </li>
                  </ul>
                </SpaceContainer>
              </Left>
            </SectionWrapper>
          </Section>
          <Section background="#FFFFFF">
            <SectionWrapper px="25px">
              <Left>
                <Title fontSize="36px" mfontSize="28px">
                  2. Storage of Personal Data
                </Title>
                <SpaceContainer mt="40">
                  We employ appropriate technical and organizational security
                  measures at all times to protect the information we collect
                  from you. We use multiple electronic, procedural, and physical
                  security measures to protect against unauthorized or unlawful
                  use or alteration of information, and against any accidental
                  loss, destruction, or damage to information. However, no
                  method of transmission over the Internet, or method of
                  electronic storage, is 100% secure. Therefore, we cannot
                  guarantee its absolute security. Further, you are responsible
                  for maintaining the confidentiality and security of your login
                  id and password, and should not provide these credentials to
                  any 3rd party.
                </SpaceContainer>
              </Left>
            </SectionWrapper>
          </Section>
          <Section background="#FFF7F1">
            <SectionWrapper px="25px">
              <Left>
                <Title fontSize="36px" mfontSize="28px">
                  3. Sharing of your Personal Data
                </Title>
                <SpaceContainer mt="40">
                  Any Personal Data that we have access to shall never be shared
                  without your consent.
                  <br />
                  <br />
                  In various processes / submission of applications / availment
                  of product offerings, we may seek your explicit consent to use
                  / share your Personal Data.
                  <br />
                  <br />
                  We will share your data with competent/ legal / statutory /
                  regulatory agencies / authorities acting on our behalf (as the
                  case may be) in following cases:
                  <br />
                  <br />
                  <li>
                    Only for enabling the provision of the insurance or other
                    financial services availed by you, strictly on a “need to
                    know” basis and subject to applicable laws.
                  </li>
                  <li>
                    It is directed or required by legal / regulatory / statutory
                    / governmental authorities under applicable laws/regulations
                    though a legally obligated request.
                  </li>
                  <li>
                    It is required by insurance companies/financial institutions
                    to verify, mitigate or prevent fraud or to manage risk or
                    recover funds in accordance with applicable
                    laws/regulations.
                  </li>
                </SpaceContainer>
              </Left>
            </SectionWrapper>
          </Section>
          <Section background="#FFFFFF">
            <SectionWrapper px="25px">
              <Left>
                <Title fontSize="36px" mfontSize="28px">
                  4. Usage of Your Personal Data
                </Title>
                <SpaceContainer mt="40">
                  We use your Personal Data in our business/insurance activities
                  for providing our or our partners’ products/services and to
                  perform, among other actions, the following:
                  <br />
                  <br />
                  <li>
                    To facilitate the insurance renewals, purchase or
                    modifications or report on these transactions;
                  </li>
                  <li>
                    To undertake research and analytics for offering or
                    improving our data processing services and their security
                    and service quality;
                  </li>
                  <li>
                    To check and process your policies submitted to us for
                    insurance data processing services and/or instructions or
                    requests received from you in respect of these services;
                  </li>
                  <li>
                    To share with you, updates on changes to the insurance
                    related services and their terms and conditions;
                  </li>
                  <li>
                    To take up or investigate any complaints/claims/disputes;
                  </li>
                  <li>
                    To respond to your queries or feedback submitted by you;
                  </li>
                  <li>
                    To verify your identity for us to provide banking services
                    to you;
                  </li>
                  <li>
                    To carry screenings or due diligence checks as lawfully
                    required by us;
                  </li>
                  <li>
                    To monitor and review insurance related services from time
                    to time;
                  </li>
                  <li>
                    To undertake financial/regulatory/management reporting, and
                    create and maintain various risk management models;
                  </li>
                  <li>For selective offers and promotions.</li>
                  <br />
                  <br />
                  We also use your Personal Data to fulfill the requirements of
                  applicable laws/regulations and/or court orders/regulatory
                  directives received by us.
                </SpaceContainer>
              </Left>
            </SectionWrapper>
          </Section>
          <Section background="#FFF7F1">
            <SectionWrapper px="25px">
              <Left>
                <Title fontSize="36px" mfontSize="28px">
                  5. Cookies
                </Title>
                <SpaceContainer mt="40">
                  <li>
                    Please note that a "cookie" is a small piece of information
                    stored by a web server on a web browser so it can be later
                    read back from that browser.
                  </li>
                  <li>
                    We may use cookie and tracking technology depending on the
                    features offered.
                  </li>
                  <li>
                    No Personal Data will be collected via cookies and other
                    tracking technology; however, if you previously provided
                    Personal Data, cookies may be tied to such information.
                  </li>
                </SpaceContainer>
              </Left>
            </SectionWrapper>
          </Section>
          <Section background="#FFFFFF">
            <SectionWrapper px="25px">
              <Left>
                <Title fontSize="36px" mfontSize="28px">
                  6. Reasonable Security Practices and Procedures
                </Title>
                <SpaceContainer mt="40">
                  We take various steps and measures to protect the security of
                  your Personal Data from misuse, loss, unauthorized access,
                  modification or disclosure. We use the latest secure server
                  layers encryption and access control on our systems.
                </SpaceContainer>
              </Left>
            </SectionWrapper>
          </Section>
          <Section background="#FFF7F1">
            <SectionWrapper px="25px">
              <Left>
                <Title fontSize="36px" mfontSize="28px">
                  7. Contact us
                </Title>
                <SpaceContainer mt="40">
                  You may contact us on any aspect of this policy or for any
                  discrepancies/grievances with respect to your Personal Data,
                  by writing to our Customer Support at{' '}
                  <a href="mailto:admin@verak.in">admin@verak.in</a>
                </SpaceContainer>
              </Left>
            </SectionWrapper>
          </Section>
          <Section background="#FFFFFF">
            <SectionWrapper px="25px">
              <Left>
                <Title fontSize="36px" mfontSize="28px">
                  8. Policy Review & Updates
                </Title>
                <SpaceContainer mt="40" mb="100">
                  This policy will be reviewed by us as and when required and
                  the same may change at any time. The latest & most updated
                  policy can always be found at{' '}
                  <a href="https://www.verak.in/privacy">
                    https://www.verak.in/privacy
                  </a>
                  <br />
                  <br />
                  This privacy policy is subject to change based on business,
                  legal and regulatory requirements. You are kindly advised to
                  review the policy periodically to keep yourself abreast of any
                  changes to the policy.
                  <br />
                  <br /> In this policy, the words “we”, “ours” and/or “us”
                  refer to Girnar Insurance Brokers Private Limited and “you” and/or
                  “your” refer to our customers.
                </SpaceContainer>
              </Left>
            </SectionWrapper>
          </Section>
        </Section>
        <Divider />
        <Footer />
      </div>
    </>
  );
};

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: {
          in: ["index", "common", "navbar", "footer", "getQuoteModal"]
        }, 
        language: {eq: $language 
      }
    }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const Left = styled.div`
  text-align: left !important;
`;

const SpaceContainer = styled.div`
  margin-top: ${({ mt }) => `${mt ? mt + 'px' : '0px'}`};
  margin-bottom: ${({ mb }) => `${mb ? mb + 'px' : '0px'}`};
  margin-left: ${({ ml }) => `${ml ? ml + 'px' : '0px'}`};
  margin-right: ${({ mr }) => `${mr ? mr + 'px' : '0px'}`};

  font-size: 18px;
  color: #000000;
  line-height: 32px;

  li {
    margin-left: 20px;
  }

  a {
    font-weight: bold;
    color: blue;
  }
`;

export default AboutUs
